<template>
  <div class="collapsible" :class="{ toggled: toggled }">
    <div class="collapsible__trigger" @click="toggle">
      <h3>{{ trigger }}</h3>

      <div class="collapsible__trigger-icon">
        <inline-svg
          ref="icon"
          :src="require('@/assets/svg/tiny-arrow-up.svg')"
        />
      </div>
    </div>
    <div class="collapsible__content" ref="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'

export default {
  name: 'Collapsible',
  props: {
    trigger: undefined
  },
  data () {
    return {
      toggled: undefined
    }
  },
  watch: {
    toggled () {
      if (this.toggled) {
        this.$emit('toggled', this)
        gsap.to(this.$refs.icon.$el, {
          ease: 'Expo.easeOut',
          duration: 0.5,
          rotation: -90
        })
        gsap.to(this.$refs.content, {
          ease: 'Expo.easeOut',
          duration: 0.5,
          height: 'auto'
        })
        gsap.to(this.$refs.content, {
          ease: 'Expo.easeOut',
          duration: 0.5,
          paddingTop: 30,
          paddingBottom: 30
        })
        gsap.to(this.$refs.content, { duration: 1, opacity: 1 })
      } else {
        gsap.to(this.$refs.content, {
          ease: 'Expo.easeOut',
          duration: 0.5,
          paddingTop: '0',
          paddingBottom: '0'
        })
        gsap.to(this.$refs.content, {
          ease: 'Expo.easeOut',
          duration: 0.5,
          height: '0'
        })
        gsap.to(this.$refs.content, {
          ease: 'Expo.easeOut',
          duration: 0.5,
          opacity: 0
        })
        gsap.to(this.$refs.icon.$el, {
          ease: 'Expo.easeOut',
          duration: 0.5,
          rotation: 90
        })
      }
    }
  },
  methods: {
    clear () {
      this.toggled = false
    },
    toggle () {
      this.toggled = !this.toggled
    }
  }
}
</script>
