<template>
  <div class="content-end">
    <TickerHeadline>WHAT'S NEXT?</TickerHeadline>
    <div class="content-end__buttons">
      <Button target="/contact">CONTACT ME</Button>
      <Button v-if="next" :target="'/' + next.target + '/' + next.slug">{{
        next.label
      }}</Button>
    </div>
  </div>
</template>

<script>
import Button from './Button'
import TickerHeadline from './TickerHeadline'
export default {
  name: 'ContentEnd',
  components: { Button, TickerHeadline },
  props: {
    next: undefined,
    target: String,
    icon: String,
    iconOnly: undefined,
    playful: undefined,
    disabled: undefined
  }
}
</script>
